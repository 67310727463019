// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-contatosuccess-js": () => import("./../src/pages/contatosuccess.js" /* webpackChunkName: "component---src-pages-contatosuccess-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orcamento-js": () => import("./../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-quemsomos-js": () => import("./../src/pages/quemsomos.js" /* webpackChunkName: "component---src-pages-quemsomos-js" */)
}

